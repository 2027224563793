@import 'assets/styles/_mixins.scss';

.password_field {
  width: 100%;
  padding: 13px 14px 15px;
  background: $black-light;
  font-weight: 400;
  font-size: 24px;
  line-height: 130%;
  color: $white;
  border-radius: 10px;

  &::placeholder {
    color: $placeholder-color;
  }

  @include maxWidth($tablet-s) {
    border-radius: 8px;
    font-size: 20px;
    line-height: 140%;
  }

  @include maxWidth($mobile-xl) {
    padding: 12px 14px 14px;
    border-radius: 6px;
    font-size: 17px;
  }

  @include maxWidth($mobile-l) {
    border-radius: 4px;
    font-size: 13px;
  }

  &__button {
    position: absolute;
    top: 55%;
    right: 20px;
    transform: translateY(-50%);

    @include maxWidth($mobile-xl) {
      top: 53%;
      right: 10px;
    }
  }
}
